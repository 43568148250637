import { Filter, SortDirection, SortOrder } from '@netpurpose/types'

export type Action<T> =
  | { type: 'setFilters'; column: keyof T; filters: Filter<T[keyof T] | unknown>[] }
  | { type: 'setSortDirection'; column: keyof T; direction: SortDirection | null }
  | { type: 'setMultiSortDirection'; column: keyof T; direction: SortDirection | null }
  | { type: 'clearFilter'; column: keyof T }
  | { type: 'clearAll' }

export type FilterState<Model> = {
  [K in keyof Model]?: { filters?: Filter<Model>[]; sortDirection?: SortDirection }
}
export type State<Model> = {
  filters: FilterState<Model>
  sortOrder: SortOrder<Model>
}

export type Reducer<Model> = (state: State<Model>, action: Action<Model>) => State<Model>

const removeSortFromOrdering = <Model>(sortOrder: SortOrder<Model>, column: keyof Model) =>
  sortOrder.filter((col) => col !== column)

const addSortToOrdering = <Model>(sortOrder: SortOrder<Model>, column: keyof Model) =>
  sortOrder.includes(column) ? sortOrder : [...sortOrder, column]

export const reducer = <Model>(state: State<Model>, action: Action<Model>): State<Model> => {
  switch (action.type) {
    case 'setFilters': {
      const previous = state.filters[action.column]
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.column]: {
            ...(previous || {}),
            filters: action.filters,
          },
        },
      }
    }
    case 'setSortDirection': {
      const previous = state.filters[action.column]
      // only one sort filter can be set at a time
      Object.keys(state.filters).map((key) => {
        const item = state.filters[key as keyof Model]
        if (item?.sortDirection) {
          delete item.sortDirection
        }
      })
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.column]: {
            ...(previous || {}),
            sortDirection: action.direction ? action.direction : undefined,
          },
        },
        // only one sortOrder can be set at a time
        sortOrder: action.direction ? [action.column] : [],
      }
    }
    case 'setMultiSortDirection': {
      const previous = state.filters[action.column]
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.column]: {
            ...(previous || {}),
            sortDirection: action.direction ? action.direction : undefined,
          },
        },
        sortOrder: action.direction
          ? addSortToOrdering(state.sortOrder, action.column)
          : removeSortFromOrdering(state.sortOrder, action.column),
      }
    }
    case 'clearFilter':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.column]: undefined,
        },
        sortOrder: removeSortFromOrdering(state.sortOrder, action.column),
      }
    case 'clearAll':
      return {
        filters: {},
        sortOrder: [],
      }

    default:
      throw new Error('bad filter action')
  }
}
