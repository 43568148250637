import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { Interceptors } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { DefaultService } from './services.gen';
import { GroupsService } from './services.gen';
import { LabelsService } from './services.gen';
import { MetricConfigService } from './services.gen';
import { ServiceAccountsService } from './services.gen';
import { SyncService } from './services.gen';
import { UsersService } from './services.gen';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

	public readonly default: DefaultService;
	public readonly groups: GroupsService;
	public readonly labels: LabelsService;
	public readonly metricConfig: MetricConfigService;
	public readonly serviceAccounts: ServiceAccountsService;
	public readonly sync: SyncService;
	public readonly users: UsersService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? '',
			VERSION: config?.VERSION ?? '0.1.0',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
			interceptors: {
				request: config?.interceptors?.request ?? new Interceptors(),
				response: config?.interceptors?.response ?? new Interceptors(),
      },
		});

		this.default = new DefaultService(this.request);
		this.groups = new GroupsService(this.request);
		this.labels = new LabelsService(this.request);
		this.metricConfig = new MetricConfigService(this.request);
		this.serviceAccounts = new ServiceAccountsService(this.request);
		this.sync = new SyncService(this.request);
		this.users = new UsersService(this.request);
	}
}
