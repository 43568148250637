import styled from 'styled-components'
import { Button } from '@netpurpose/np-ui'

export const PopUpContainer = styled.div`
  position: fixed;
  width: 24rem;
  bottom: 2.5rem;
  right: 2.5rem;
  border: 5px solid white;
  background-color: white;
  z-index: 9999;
  border-radius: 7px;
  box-shadow: 0px 4px 12px 0px #0000001a;
`

export const PopUpHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.architecture8};
  color: ${({ theme }) => theme.colors.typeDarkPrimary};
  padding: 0.5rem;
  border-radius: 2px 2px 0 0;

  text-transform: uppercase;
  font-weight: bold;
  line-height: 1rem;
  font-size: 0.75rem;
`

export const ExportItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
`

export const ExportNameAndIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 21rem;
`

export const ExportName = styled.div`
  line-height: 1.25rem;
  font-size: 0.875rem;
`

export const ExportSuccessText = styled.div`
  line-height: 1rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.semanticPositive};
`

export const ExportFailureText = styled.div`
  line-height: 1rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.semanticNegative};
`

export const StatusIconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const LoadingIndicator = styled(Button)`
  border: none;
  padding: 0 0.25rem;
  width: 2rem;
  &:hover {
    background-color: transparent;
  }
`
