import { useEffect, useState } from 'react'
import { PaginationConfig, TablePaginationConfig } from '@netpurpose/types'
import { apiConfig } from '../config'

export const usePagination = ({
  perPage = apiConfig.defaultFetchSize,
  withTotalRow = false,
}: {
  perPage: number
  withTotalRow?: boolean
}): PaginationConfig => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined)
  const [pageSize, setPageSize] = useState<number>(perPage)

  const getPageSize = (currentPageNum: number | undefined) =>
    // set + 1 to allow Total row in the table on the first page
    withTotalRow && currentPageNum === 1 ? perPage + 1 : perPage

  // @ts-expect-error
  const initialTablePaginationConfig: TablePaginationConfig = {
    ...apiConfig.defaultTablePagination,
    pageSize,
    current: currentPage,
    total: totalCount,
  }
  const [tablePaginationConfig, setTablePaginationConfig] = useState<TablePaginationConfig>(
    initialTablePaginationConfig,
  )

  const handleTableChange = (pagination: TablePaginationConfig) => {
    if (pagination.current) {
      setCurrentPage(pagination.current)
    }
    if (pagination.pageSize) {
      setPageSize(getPageSize(pagination.current))
    }
  }

  useEffect(() => {
    // @ts-expect-error
    setTablePaginationConfig({
      ...initialTablePaginationConfig,
      pageSize: getPageSize(currentPage),
      current: currentPage,
      total: totalCount,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage, totalCount])

  // @ts-expect-error
  return {
    currentPage,
    totalCount,
    pageSize,
    setCurrentPage,
    setTotalCount,
    setPageSize,
    handleTableChange,
    tablePaginationConfig,
  }
}
