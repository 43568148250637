import styled from 'styled-components'

export const NavIconContainer = styled.div`
  color: ${({ theme }) => theme.colors.typePrimary};
  &:hover {
    color: ${({ theme }) => theme.colors.actionDarkLink};
  }
`

export const NavIconsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-right: 1.5rem;
  align-items: center;
`
