import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ONE_HOUR_MS } from '@netpurpose/types'
import { ensureTimestampInUTCFormat } from '@netpurpose/utils'
import { GeneratedFactApi } from '../../GeneratedApi'
import { snakeToCamelKeys } from '../../utils'

export const useListExports = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['listExports'],
    queryFn: () => GeneratedFactApi.results.listExports(),
    staleTime: ONE_HOUR_MS,
  })

  const transformedData = useMemo(
    () =>
      data
        ? data.map((item) => ({
            ...snakeToCamelKeys(item),
            createdAt: ensureTimestampInUTCFormat(item.created_at),
            completedAt: ensureTimestampInUTCFormat(item.completed_at),
          }))
        : undefined,
    [data],
  )

  return { ...rest, data: transformedData }
}

export type ExportList = NonNullable<ReturnType<typeof useListExports>['data']>
