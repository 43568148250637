import { TooltipProps as AntdTooltipProps } from 'antd'
import { FC } from 'react'
import { AnyObject } from '@netpurpose/types'
import { Tooltip } from '../components'

export type TooltipProps = AntdTooltipProps & {
  tooltip: string
  showTooltip?: boolean
}

// extra span due to this bug: https://github.com/react-component/tooltip/issues/18
export const withTooltip =
  <P extends AnyObject>(Component: FC<P>) =>
  (props: P & TooltipProps) => {
    const { tooltip, showTooltip = true, align, ...rest } = props

    const isIconComponent = typeof props['icon'] === 'string'

    return showTooltip ? (
      <Tooltip title={tooltip} {...(align ? { align } : {})}>
        <span>
          {/* Avoid duplicating the custom tooltip with the browser's native tooltip. */}
          <Component
            {...(rest as unknown as P)}
            {...(isIconComponent ? { hideTooltip: true } : {})}
          />
        </span>
      </Tooltip>
    ) : (
      <span>
        <Component
          {...(rest as unknown as P)}
          {...(isIconComponent ? { hideTooltip: true } : {})}
        />
      </span>
    )
  }
