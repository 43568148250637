import { ReactQueryDevtools } from '@tanstack/react-query-devtools' // not included in prod build
import Head from 'next/head'
import { FC, ReactNode } from 'react'
import { isDevEnv } from '@netpurpose/utils'

type Props = {
  children?: ReactNode
  title?: string
}

const BaseLayout: FC<Props> = ({ children, title = 'Net Purpose' }) => (
  <div>
    <Head>
      <link rel="icon" href="/favicon.ico" />
      <title>{title}</title>
    </Head>
    {children}
    {isDevEnv() && <ReactQueryDevtools />}
  </div>
)

export default BaseLayout
