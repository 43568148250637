// TODO: improve typing to remove anys
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  keepPreviousData,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect, useMemo, useState } from 'react'
import {
  AnyObject,
  ById,
  Filters,
  FiltersOperator,
  PaginationConfig,
  SortDirection,
} from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import { ModelApiName } from '../Api'
import { apiConfig } from '../config'
import { AbstractModelApi, AdditionalParams, PaginatedListResult } from '../models'
import {
  BackendPaginationParams,
  formatFilterParams,
  formatPaginationParams,
  formatSortParams,
  ReverseFieldMap,
} from '../queryBuilder'
import { useApi } from './useApi'
import { useFilters } from './useFilters'
import { usePagination } from './usePagination'
import { useSyncUrlWithFiltersAndPagination } from './useSyncUrlWithFiltersAndPagination'

type GetUsePaginatedEndpoint<
  Model,
  QueryArgs extends Record<string, unknown>,
  FilterModel = Partial<Model>,
> = {
  queryCacheKey: string
  queryFunction: <Params extends BackendPaginationParams>(
    queryParams: Params,
    args: QueryArgs,
  ) => Promise<PaginatedListResult<Model> | null>
  reverseFieldMap?: ReverseFieldMap<keyof Model>
  operator?: FiltersOperator
  queryOptions?: QueryOptions<FilterModel>
}

export type QueryOptions<FilterModel = unknown> = {
  useUrlSync?: boolean
  defaultSortField?: [keyof FilterModel, SortDirection]
}

export type PaginatedQueryArgs<Model, QueryArgs = Record<string, unknown>> = {
  queryFunctionArgs: QueryArgs
  perPage?: number
  withTotalRow?: boolean
  queryParams?: Filters<Model>
  // TODO: type this better if possible
  // reactQueryParams?: UseQueryOptions<QueryArgs>
  reactQueryParams?: AnyObject & { staleTime?: number }
  multiFieldSortingEnabled?: boolean
} & Pick<GetUsePaginatedEndpoint<Model, any>, 'operator' | 'queryOptions'>

const defaultQueryOptions: Pick<QueryOptions<unknown>, 'useUrlSync'> = {
  useUrlSync: true,
}

const getQueryFunctionEnabled = (
  enabledParam: boolean | undefined,
  defaultSortFieldUsed: boolean,
  initialSortOrderApplied: boolean,
): boolean => {
  const enabledParamIsSet = typeof enabledParam === 'boolean'
  if (enabledParamIsSet && defaultSortFieldUsed) {
    return enabledParam && initialSortOrderApplied
  }
  if (enabledParamIsSet) {
    return enabledParam
  }
  if (defaultSortFieldUsed) {
    return initialSortOrderApplied
  }
  return true
}

export const applyDefaultQueryOptions = <FilterModel>(
  queryOptions?: Partial<QueryOptions<FilterModel>>,
) => ({
  ...defaultQueryOptions,
  ...(queryOptions || {}),
})

export const getUsePaginatedEndpoint =
  <Model, QueryArgs extends Record<string, unknown>, FilterModel extends Model = Model>({
    queryCacheKey,
    queryFunction,
    reverseFieldMap,
    operator,
    queryOptions,
  }: GetUsePaginatedEndpoint<Model, QueryArgs, FilterModel>) =>
  ({
    queryFunctionArgs = {} as QueryArgs,
    perPage = apiConfig.defaultFetchSize,
    withTotalRow = false,
    queryParams = {},
    reactQueryParams = {},
    multiFieldSortingEnabled,
  }: PaginatedQueryArgs<Model, QueryArgs>) => {
    const resolvedQueryOptions = applyDefaultQueryOptions(queryOptions)
    const { useUrlSync } = resolvedQueryOptions
    const { currentPage, pageSize, totalCount, setTotalCount, ...pagination } = usePagination({
      perPage,
      withTotalRow,
    })
    const filterConfig = useFilters<FilterModel>({
      urlKey: queryCacheKey,
      queryOptions: resolvedQueryOptions,
      ...(multiFieldSortingEnabled ? { multiFieldSortingEnabled } : {}),
    })

    useSyncUrlWithFiltersAndPagination({
      queryCacheKey,
      filters: filterConfig.filters,
      sorting: filterConfig.sorting,
      sortOrder: filterConfig.sortOrder,
      pagination: {
        currentPage,
        pageSize,
      },
      enabled: !!useUrlSync,
    })
    const apiPage = currentPage - 1

    const formattedPaginationParams = formatPaginationParams({ page: apiPage, pageSize })

    const formattedFilterParams =
      reverseFieldMap &&
      formatFilterParams({ ...filterConfig.filters, ...queryParams }, reverseFieldMap, operator)

    const formattedSortParams =
      reverseFieldMap &&
      formatSortParams(
        filterConfig.sorting,
        filterConfig.sortOrder,
        reverseFieldMap as ReverseFieldMap<keyof FilterModel>,
      )

    const allQueryParams = useMemo(
      () => ({
        ...formattedPaginationParams,
        ...formattedFilterParams,
        ...formattedSortParams,
      }),
      [formattedFilterParams, formattedPaginationParams, formattedSortParams],
    )

    const [initialSortOrderApplied, setInitialSortOrderApplied] = useState(false)

    const queryClient = useQueryClient()
    const { data, ...reactQuerySpread } = useQuery({
      queryKey: [queryCacheKey, allQueryParams, queryFunctionArgs],
      queryFn: () => queryFunction(allQueryParams, queryFunctionArgs),
      ...reactQueryParams,
      placeholderData: keepPreviousData,
      staleTime: reactQueryParams?.staleTime || apiConfig.defaultPaginatedStaleTime,

      enabled: getQueryFunctionEnabled(
        reactQueryParams['enabled'] as boolean | undefined,
        Boolean(resolvedQueryOptions.defaultSortField),
        initialSortOrderApplied,
      ),
    })

    const stringifiedFilters = JSON.stringify(filterConfig.filters)

    useEffect(() => {
      const activeFilters = Object.keys(filterConfig.filters)
      if (resolvedQueryOptions.defaultSortField && activeFilters.length === 0) {
        filterConfig.setSortDirection(...resolvedQueryOptions.defaultSortField)
        // Prevent extra unnecessary request to fetch data without initial filter applied.
        setInitialSortOrderApplied(true)
      }
      // Use stringifiedFilters in dep array to ensure clearing filters keeps
      // default sorting if set.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringifiedFilters])

    // Invalidate current query, and if active, it will be re-fetched
    const refresh = () =>
      queryClient.invalidateQueries({
        queryKey: [queryCacheKey, allQueryParams, queryFunctionArgs],
      })
    // Bust the cache completely for all pages.
    const fullRefresh = () =>
      queryClient.invalidateQueries({
        queryKey: [queryCacheKey],
      })

    useEffect(() => {
      // prefetch the next page
      if (data?.hasNext) {
        const prefetchParams = {
          ...allQueryParams,
          page: apiPage + 1,
          limit: perPage,
        }
        queryClient.prefetchQuery({
          queryKey: [queryCacheKey, prefetchParams, queryFunctionArgs],
          queryFn: () => queryFunction(prefetchParams, queryFunctionArgs),
          staleTime: apiConfig.defaultPaginatedStaleTime,
        })
      }
    }, [data, allQueryParams, apiPage, queryClient, queryFunctionArgs, queryParams, perPage])

    if (valueIsDefined(data) && totalCount !== data?.totalCount) {
      setTotalCount(data.totalCount)
    }

    // @ts-expect-error
    const paginationConfig: PaginationConfig = {
      ...pagination,
      currentPage,
      pageSize,
      totalCount,
      setTotalCount,
    }

    return {
      data,
      paginationConfig,
      filterConfig,
      refresh,
      fullRefresh,
      ...reactQuerySpread,
    }
  }

export const getUsePaginatedModel = <
  ListModel extends { id: number } | { id: string },
  T extends AbstractModelApi<any, any, ListModel, any>,
  FilterModel extends ListModel = ListModel,
>(
  queryCacheKey: string,
  modelApiName: ModelApiName,
) => {
  return ({
    perPage = apiConfig.defaultFetchSize,
    queryParams = {},
    reactQueryParams = {},
    multiFieldSortingEnabled = false,
    queryFunctionArgs,
    operator,
    queryOptions,
  }: PaginatedQueryArgs<FilterModel, Record<string, any>>) => {
    const { api } = useApi()
    const modelApi = api.getApiByName(modelApiName) as unknown as T
    // @ts-expect-error
    return getUsePaginatedEndpoint<ListModel, AdditionalParams, FilterModel>({
      queryCacheKey,
      queryFunction: modelApi.getPaginatedList,
      reverseFieldMap: modelApi.reverseFieldMap,
      operator,
      queryOptions,
    })({
      queryFunctionArgs,
      perPage,
      queryParams,
      reactQueryParams,
      multiFieldSortingEnabled,
    })
  }
}

export const getUseModel =
  <
    DetailModel extends { id: number } | { id: string },
    T extends AbstractModelApi<any, any, any, DetailModel>,
  >(
    queryCacheKey: string,
    modelApiName: ModelApiName,
    featureHeader?: { [key: string]: string },
  ) =>
  (
    id: DetailModel['id'] | undefined,
    reactQueryParams?: {
      enabled?: boolean
      staleTime?: number
    },
    params: AdditionalParams = {},
  ) => {
    const { api } = useApi()
    const modelApi = api.getApiByName(modelApiName) as unknown as T
    const query = useQuery<DetailModel>({
      queryKey: [queryCacheKey, id],
      queryFn: () => modelApi.getById(id, featureHeader, params),
      enabled: Boolean(id),
      ...reactQueryParams,
    })

    return query
  }

export const getUseModelListFromPaginatedApi =
  <
    ListModel extends { id: number } | { id: string },
    T extends AbstractModelApi<any, any, ListModel, any>,
  >(
    queryCacheKey: string,
    modelApiName: ModelApiName,
  ) =>
  (
    queryParams: Record<string, unknown> = {},
    reactQueryParams?: {
      enabled?: boolean
      staleTime?: number
    },
  ) => {
    const { api } = useApi()
    const modelApi = api.getApiByName(modelApiName) as unknown as T
    const queryResult = useQuery({
      queryKey: [queryCacheKey, queryParams],
      queryFn: () => modelApi.getListFromPaginatedApi(queryParams),
      staleTime: apiConfig.defaultListStaleTime,
      ...reactQueryParams,
    })

    return {
      ...queryResult,
      data: queryResult.data?.results,
    }
  }

export const getUseModelById =
  <
    ListModel extends { id: number } | { id: string },
    T extends AbstractModelApi<any, any, ListModel, any>,
  >(
    queryCacheKey: string,
    modelApiName: ModelApiName,
  ) =>
  (
    ids: ListModel['id'][] = [],
    reactQueryParams?: {
      enabled?: boolean
      staleTime?: number
    },
    additionalParams: AdditionalParams = {},
  ): {
    isFetching: boolean
    data: ById<ListModel> | undefined
    actions: {
      refetch: (
        options?: (RefetchOptions & RefetchQueryFilters) | undefined,
      ) => Promise<QueryObserverResult<ListModel[], unknown>>
    }
  } => {
    const { api } = useApi()
    const modelApi = api.getApiByName(modelApiName) as unknown as T
    const uniqueIds = useMemo(() => [...new Set(ids)], [ids])

    const {
      data: records,
      isFetching,
      refetch,
    } = useQuery({
      queryKey: [queryCacheKey, uniqueIds],
      queryFn: () => modelApi.getListByIds(uniqueIds, additionalParams),
      staleTime: apiConfig.defaultStaleTime,
      ...reactQueryParams,
    })

    const recordsById = useMemo(
      () =>
        records?.reduce((acc, record) => ({ [record.id]: record, ...acc }), {} as ById<ListModel>),
      [records],
    )

    return {
      isFetching,
      data: recordsById,
      actions: { refetch },
    }
  }

export const getUseCreateModel =
  <
    DetailModel extends { id: number } | { id: string },
    T extends AbstractModelApi<any, any, any, DetailModel>,
  >(
    modelApiName: ModelApiName,
    invalidateQueryCacheKeys?: string[],
  ) =>
  () => {
    const { api } = useApi()
    const modelApi = api.getApiByName(modelApiName) as unknown as T
    const queryClient = useQueryClient()

    return useMutation({
      mutationFn: ({ model }: { model: Partial<DetailModel> }) => modelApi.create(model),
      onSuccess: () => {
        invalidateQueryCacheKeys?.forEach((key) => {
          queryClient.invalidateQueries({
            queryKey: [key],
          })
        })
      },
    })
  }

export const getUseUpdateModel =
  <
    DetailModel extends { id: number } | { id: string },
    T extends AbstractModelApi<any, any, any, DetailModel>,
  >(
    modelApiName: ModelApiName,
    invalidateQueryCacheKeys?: string[],
  ) =>
  () => {
    const { api } = useApi()
    const modelApi = api.getApiByName(modelApiName) as unknown as T
    const queryClient = useQueryClient()

    return useMutation({
      mutationFn: ({ id, model }: { id: DetailModel['id']; model: Partial<DetailModel> }) =>
        modelApi.update(id, model),
      onSuccess: () => {
        invalidateQueryCacheKeys?.forEach((key) => {
          queryClient.invalidateQueries({
            queryKey: [key],
          })
        })
      },
    })
  }

export const getUseDeleteModel =
  <
    DetailModel extends { id: number } | { id: string },
    T extends AbstractModelApi<any, any, any, DetailModel>,
  >(
    modelApiName: ModelApiName,
    invalidateQueryCacheKeys?: string[],
  ) =>
  () => {
    const { api } = useApi()
    const modelApi = api.getApiByName(modelApiName) as unknown as T
    const queryClient = useQueryClient()

    return useMutation<boolean, AxiosError, { id: DetailModel['id'] }>({
      mutationFn: ({ id }) => modelApi.delete(id),
      onSuccess: () => {
        invalidateQueryCacheKeys?.forEach((key) => {
          queryClient.invalidateQueries({
            queryKey: [key],
          })
        })
      },
    })
  }
