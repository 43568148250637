import { MouseEvent } from 'react'
import { ThemeColor } from '../../themes'
import { Icon, IconProps } from '../Icon'
import { StyledButton } from './IconButton.style'

export type Props = Omit<IconProps, 'alt'> & {
  iconAlt: string
  iconColor?: ThemeColor
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  alignCenter?: boolean
  hasHoverState?: boolean
  disabled?: boolean
}

export const IconButton = ({
  iconAlt,
  inheritColor = false,
  iconColor,
  onClick,
  alignCenter = false,
  hasHoverState = true,
  disabled = false,
  ...props
}: Props) => {
  // type="button" to prevent form submit trigger where we rely on button type="submit"
  // ie., in FactForm component
  if (disabled) {
    iconColor = 'typeTertiary'
  }
  return (
    <StyledButton
      type="button"
      onClick={onClick}
      $alignCenter={alignCenter}
      $hasHoverState={hasHoverState}
      disabled={disabled}
    >
      <Icon
        alt={iconAlt}
        {...(iconColor ? { color: iconColor } : {})}
        inheritColor={inheritColor}
        {...props}
      />
    </StyledButton>
  )
}
