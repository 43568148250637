import { MenuProps } from 'antd'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { useLoggedIn, useModalContext } from '@netpurpose/core'
import {
  CalloutMenu,
  Icon,
  NextLink as Link,
  NavIcon,
  Navbar as NPNavbar,
  withTooltip,
} from '@netpurpose/np-ui'
import { valueIsDefined } from '@netpurpose/utils'
import { ApiKeyOption } from '#components/apiKey/ApiKeyButton'
import { ApiKeyModal } from '#components/apiKey/ApiKeyModal'
import { useAnalytics } from '#context/analytics'
import { useAuth } from '#context/auth'
import { config } from '#services/config'
import { getBaseRoutePart } from '#services/routes'
import { AnalyticsEventName, AnalyticsLinkName } from '#types/analytics'
import { canAccessExplorePage, canGetAPIKey } from '#utils'
import { GlobalSearch } from '../GlobalSearch'
import { ExportHistoryDropdown } from './ExportHistoryDropdown'
import { NavIconContainer, NavIconsContainer } from './Navbar.style'

const NavIconWithTooltip = withTooltip(NavIcon)

const LibraryItem = () => {
  const analytics = useAnalytics()

  return (
    <Link
      href={config.routes.library}
      onClick={() =>
        analytics?.logEvent(AnalyticsEventName.LinkClick, {
          link_name: AnalyticsLinkName.NavBarViewLibrary,
        })
      }
    >
      <NavIconContainer>
        <NavIconWithTooltip icon="Book" alt="Library" tooltip="Library" inheritColor />
      </NavIconContainer>
    </Link>
  )
}

const WhatsNewItem = () => {
  const analytics = useAnalytics()

  return (
    <a
      // This URL shouldn't change, even if updates are made to the page.
      href="https://netpurpose.notion.site/Web-App-Releases-12710608a8a44133a73e27ae4dcd96d9"
      target="_blank"
      onClick={() =>
        analytics?.logEvent(AnalyticsEventName.LinkClick, {
          link_name: AnalyticsLinkName.NavBarViewWhatsNew,
        })
      }
    >
      <NavIconContainer>
        <NavIconWithTooltip
          icon="LightningBolt"
          alt="What's new"
          tooltip="What's new"
          width={12}
          height={24}
          inheritColor
        />
      </NavIconContainer>
    </a>
  )
}

const MenuContent = () => {
  const { user } = useLoggedIn()

  return (
    <NavIconsContainer>
      {user && canAccessExplorePage(user) && <GlobalSearch />}
      <ExportHistoryDropdown />
      <LibraryItem />
      <WhatsNewItem />
    </NavIconsContainer>
  )
}

export const Navbar: FC = () => {
  const router = useRouter()
  const { logout, isAuthenticated, clearMFAState } = useAuth()
  const { user } = useLoggedIn()
  const { routes } = config
  const { isModalOpen } = useModalContext()

  const getSelectedRoute = () => getBaseRoutePart(router.pathname)

  const items: MenuProps['items'] = [
    {
      label: <NPNavbar.NavbarLink route={routes.goals} displayText="Goals" />,
      key: getBaseRoutePart(routes.goals),
    },
    {
      label: <NPNavbar.NavbarLink route={routes.portfolioList} displayText="Portfolios" />,
      key: getBaseRoutePart(routes.portfolioList),
    },
    user && canAccessExplorePage(user)
      ? {
          label: <NPNavbar.NavbarLink route={routes.explore} displayText="Explore" />,
          key: getBaseRoutePart(routes.explore),
        }
      : null,
  ].filter(valueIsDefined)

  const analytics = useAnalytics()

  return (
    <>
      <NPNavbar
        logoLink={routes.landingPage}
        logout={() => {
          clearMFAState()
          logout()
        }}
        isAuthenticated={isAuthenticated}
        getSelectedRouteName={getSelectedRoute}
        MenuContent={MenuContent}
        userDisplayName={user?.firstName || null}
        accountMenuOptions={[
          {
            label: (
              <Link
                href={config.routes.metricConfigs}
                onClick={() =>
                  analytics?.logEvent(AnalyticsEventName.LinkClick, {
                    link_name: AnalyticsLinkName.ToMetricConfigListFromNavBarMenu,
                  })
                }
              >
                <CalloutMenu.Item
                  key="metric-configuration"
                  label="Metric configuration"
                  renderIcon={() => <Icon icon="Settings" alt="Settings" />}
                />
              </Link>
            ),
            key: 'metric-configuration-option',
          },
          canGetAPIKey(user) ? { label: <ApiKeyOption />, key: 'api-key-option' } : null,
        ].filter(valueIsDefined)}
        items={items}
      />
      {isModalOpen.apiKey && <ApiKeyModal />}
    </>
  )
}
