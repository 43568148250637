import { useRouter } from 'next/router'
import qs from 'qs'
import { useEffect, useMemo } from 'react'
import { Filters, PaginationConfig, SortDirection, SortOrder } from '@netpurpose/types'
import { getOtherQueries, updateUrlQuery } from '../utils'

export const useSyncUrlWithFiltersAndPagination = <Model>({
  queryCacheKey,
  filters,
  sorting,
  sortOrder,
  pagination,
  enabled = true,
}: {
  queryCacheKey: string
  filters: Filters<Model>
  sorting: Partial<Record<keyof Model, SortDirection>>
  sortOrder: SortOrder<Model>
  pagination: Pick<PaginationConfig, 'currentPage' | 'pageSize'>
  enabled?: boolean
}) => {
  const router = useRouter()

  const filtersQuery = useMemo(
    () => ({
      ...(Object.keys(filters).length ? { fc: filters } : {}),
      ...(Object.keys(sorting).length ? { sc: sorting } : {}),
      ...(sortOrder.length ? { so: sortOrder } : {}),
      ...(pagination?.currentPage ? { pc: pagination } : {}),
    }),
    [filters, pagination, sorting, sortOrder],
  )

  const otherQueries = getOtherQueries(router.query, queryCacheKey)

  const query = useMemo(
    () => ({
      ...(Object.keys(otherQueries).length ? otherQueries : {}),
      ...(Object.keys(filtersQuery).length ? { [queryCacheKey]: qs.stringify(filtersQuery) } : {}),
    }),
    [filtersQuery, otherQueries, queryCacheKey],
  )

  useEffect(() => {
    if (enabled) {
      updateUrlQuery(query, router, queryCacheKey)
    }
  }, [enabled, query, router, queryCacheKey])
}
