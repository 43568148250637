import { useMutation } from '@tanstack/react-query'
import { useApi } from '@netpurpose/api'

export const useMakeApiKey = () => {
  const { api } = useApi()

  const { mutate: submit, isPending, data } = useMutation({ mutationFn: api.apiKey.getApiKey })

  return {
    isFetching: isPending,
    data,
    actions: { submit },
    utils: null,
  }
}
