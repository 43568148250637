import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { isString, Result, ResultStatus } from '@netpurpose/types'
import { useApi } from '../useApi'

const getIsResultInProgress = (resultStatus?: ResultStatus | null) =>
  resultStatus === 'pending' || resultStatus === 'started'

export const usePollAsyncTask = <ResultType = unknown>({
  resultId,
  onSuccess,
  onError,
  intervalInMs = 1000,
}: {
  resultId?: string | null
  onSuccess?: (data: Result<ResultType>) => void
  onError?: (err: Result<ResultType>) => void
  intervalInMs?: number
}) => {
  const { api } = useApi()
  const {
    data: taskResult,
    isFetching,
    isRefetching,
    error,
  } = useQuery({
    queryKey: ['asyncTask', resultId],
    queryFn: () => api.result.getResult<ResultType>(resultId),
    enabled: Boolean(resultId),

    refetchInterval: (query) =>
      getIsResultInProgress(query.state.data?.status) ? intervalInMs : false,
  })
  const isInProgress = getIsResultInProgress(taskResult?.status)

  useEffect(() => {
    if (taskResult?.status === 'complete') {
      onSuccess?.(taskResult)
    }
    if (taskResult && ['failed', 'unknown'].includes(taskResult?.status)) {
      onError?.(taskResult)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskResult])

  return {
    data: { taskResult },
    isFetching: isFetching || isRefetching || isInProgress,
    error,
  }
}

export const useAsyncTaskWithAction = <
  Action extends (payload: Parameters<Action>[number]) => Promise<string>,
>(
  action: Action,
) => {
  const [resultId, setResultId] = useState<string | null>(null)
  const [latestResultData, setLatestResultData] = useState<Result<string> | null>(null)
  const {
    data: { taskResult },
    isFetching,
  } = usePollAsyncTask<string>({
    resultId,
    onSuccess: (data: Result<string>) => {
      setLatestResultData(data)
      setResultId(null)
    },
  })

  const { mutate, isPending } = useMutation({
    mutationFn: (payload: Parameters<Action>[number]) => action(payload),
    onSuccess: (data) => {
      if (isString(data) || data === null) {
        setResultId(data)
      }
    },
  })

  return {
    data: {
      currentJobStatus: taskResult ? taskResult?.status : null,
      latestResultData,
    },
    actions: { action: mutate },
    isFetching: isPending || isFetching,
    utils: undefined,
  }
}
