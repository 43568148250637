import { useCallback, useEffect, useReducer } from 'react'
import { Filter, FilterConfig, isNumber, isString, SortDirection } from '@netpurpose/types'
import { QueryOptions } from '../useModel'
import { useTableConfigFromUrl } from '../useTableConfigFromUrl'
import { reducer, Reducer, State } from './reducer'
import { selectFilters, selectSorting } from './selectors'

const isMeaningfulValue = (value: unknown): boolean => {
  if (isNumber(value)) {
    return true
  }
  if (isString(value)) {
    return value !== ''
  }
  return !!value
}

export const hasFiltersApplied = <Model>(filters: Filter<Model[keyof Model]>[] | undefined) => {
  return filters?.some((filter) => isMeaningfulValue(filter.value)) ?? false
}

export const hasSortingApplied = (sort: SortDirection | undefined) => !!sort

export const useFilters = <FilterModel>({
  urlKey,
  queryOptions,
  multiFieldSortingEnabled,
}: {
  urlKey: string
  queryOptions: QueryOptions<FilterModel>
  multiFieldSortingEnabled?: boolean
}): FilterConfig<FilterModel> => {
  const { useUrlSync, defaultSortField } = queryOptions

  const defaultSortKey = defaultSortField?.[0]
  const defaultSortDirection = defaultSortField?.[1]

  const [state, dispatch] = useReducer<Reducer<FilterModel>>(reducer, {
    filters:
      defaultSortKey && defaultSortDirection
        ? { [defaultSortKey]: { sortDirection: defaultSortDirection } }
        : {},
    sortOrder: defaultSortKey ? [defaultSortKey] : [],
  } as State<FilterModel>)

  const clearFilter = useCallback(
    (column: keyof FilterModel) => dispatch({ type: 'clearFilter', column }),
    [dispatch],
  )
  const setFilters = useCallback(
    <FilterValue>(column: keyof FilterModel, filters: Filter<FilterValue>[]) => {
      dispatch({ type: 'setFilters', column, filters })
    },
    [dispatch],
  )
  const clearAllFilters = useCallback(() => dispatch({ type: 'clearAll' }), [dispatch])
  const setSortDirection = useCallback(
    (column: keyof FilterModel, direction: SortDirection | null) =>
      dispatch({
        type: multiFieldSortingEnabled ? 'setMultiSortDirection' : 'setSortDirection',
        column,
        direction,
      }),
    [multiFieldSortingEnabled, dispatch],
  )

  const {
    filters: filtersFromUrl,
    sorting: sortingFromUrl,
    sortOrder: sortOrderFromUrl,
  } = useTableConfigFromUrl<FilterModel>({
    urlKey,
    enabled: !!useUrlSync,
  })

  const filters = selectFilters(state)
  const sorting = selectSorting(state)

  useEffect(() => {
    if (filtersFromUrl) {
      for (const column of Object.keys(filtersFromUrl)) {
        dispatch({
          type: 'setFilters',
          column: column as keyof FilterModel,
          filters: filtersFromUrl[column as keyof FilterModel] || [],
        })
      }
    }
    if (sortingFromUrl || sortOrderFromUrl) {
      const dispatchAction = (column: keyof FilterModel | string) =>
        dispatch({
          type: multiFieldSortingEnabled ? 'setMultiSortDirection' : 'setSortDirection',
          column: column as keyof FilterModel,
          direction: sortingFromUrl[column as keyof FilterModel] ?? null,
        })
      if (sortOrderFromUrl) {
        sortOrderFromUrl.forEach((column) => dispatchAction(column))
      } else {
        Object.keys(sortingFromUrl).forEach((column) => dispatchAction(column))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    filters,
    setFilters,
    clearFilter,
    clearAllFilters,
    sorting,
    sortOrder: state.sortOrder,
    setSortDirection,
    isActive: (column) =>
      hasFiltersApplied<FilterModel>(filters[column]) || hasSortingApplied(sorting[column]),
  }
}
