import { FC, useEffect, useState } from 'react'
import { useModalContext } from '@netpurpose/core'
import { ButtonWithIcon, Icon, Modal, Spinner, Text } from '@netpurpose/np-ui'
import { useMakeApiKey } from '#hooks/useMakeApiKey'
import { Monospaced, StatusContainer, TextContainer, TokenContainer } from './ApiKeyModal.style'

const SuccessBox = () => (
  <StatusContainer>
    <Icon color="green" icon="Success" alt="Status" />
    Copied!
  </StatusContainer>
)

const FailBox = () => (
  <StatusContainer>
    <Icon color="red" icon="XLarge" alt="Failure" />
    Failed!
  </StatusContainer>
)

const CopyButton = ({ token }: { token: string }) => {
  const [isCopied, setIsCopied] = useState<boolean | null>(null)
  const copy = async () => {
    try {
      await navigator.clipboard.writeText(token)
      setIsCopied(true)
    } catch (err) {
      console.error(err)
      setIsCopied(false)
    }
  }
  switch (isCopied) {
    case true:
      return <SuccessBox />
    case false:
      return <FailBox />
    default:
      return (
        <ButtonWithIcon icon="Copy" iconAlt="Copy" level="primary" onClick={copy}>
          Copy
        </ButtonWithIcon>
      )
  }
}

const TokenBox = ({ token }: { token: string }) => {
  return (
    <TokenContainer>
      <Monospaced>{token}</Monospaced>
    </TokenContainer>
  )
}

export const ApiKeyModal: FC = () => {
  const { isModalOpen, closeModal } = useModalContext()

  const {
    data,
    isFetching,
    actions: { submit },
  } = useMakeApiKey()

  useEffect(() => {
    if (isModalOpen.apiKey) {
      submit()
    }
  }, [submit, isModalOpen.apiKey])

  const token = data?.token

  return (
    <Modal open={isModalOpen.apiKey} title="API Key" onClose={() => closeModal('apiKey')}>
      <Modal.ContentContainer>
        <Modal.HeaderText>Your API Key</Modal.HeaderText>
        <TextContainer>
          <Text color="typeSecondary">
            This is your API key. Save it in a safe place. You should treat it with the same level
            of security as your username and password.
          </Text>
          <Text>
            This code is specific to your user, and we do not keep a record of it. If you lose it,
            you can generate a new one.
          </Text>
        </TextContainer>
        {isFetching && <Spinner />}
        {token && <TokenBox token={token} />}
      </Modal.ContentContainer>
      {token && (
        <Modal.FooterContainer>
          <CopyButton token={token} />
        </Modal.FooterContainer>
      )}
    </Modal>
  )
}
