import styled from 'styled-components'

export const Container = styled.div<{ $withBorder?: boolean }>`
  display: flex;
  gap: 1rem;
  padding: 0.75rem 1rem 0.625rem 1rem;
  ${({ $withBorder, theme }) =>
    $withBorder ? `border: 1px solid ${theme.colors.architecture3}; border-radius: 4px;` : ''}
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  cursor: default;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const TextContainer = styled.div`
  flex: 1;
`

export const ExportName = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.typePrimary};
`

export const StatusTitleDateContainer = styled.div`
  display: flex;

  gap: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.typeSecondary};
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`
