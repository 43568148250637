import { FC, ReactNode } from 'react'
import { Layout } from '@netpurpose/np-ui'
import { AlertBanner } from '#components/common/AlertBanner'
import BaseLayout from '#components/common/Layout/BaseLayout'
import { Navbar } from '#components/common/Navbar'
import { useTasks } from '#context/tasks'
import { ExportPopUp } from '../ExportPopUp'

type Props = {
  children?: ReactNode
  title?: string
}

const LoggedInLayout: FC<Props> = ({ children, title = 'Net Purpose' }) => {
  const {
    portfolioExportRequests,
    portfolioPAIsExportRequests,
    portfolioSDGsExportRequests,
    portfolioSDGRevenueExportRequests,
    dataDictionaryExportRequests,
    entityExportRequests,
  } = useTasks()

  const hasExportRequests =
    !!portfolioExportRequests.length ||
    !!portfolioPAIsExportRequests.length ||
    !!portfolioSDGsExportRequests.length ||
    !!portfolioSDGRevenueExportRequests.length ||
    !!dataDictionaryExportRequests.length ||
    !!entityExportRequests.length

  return (
    <BaseLayout title={title}>
      <Layout>
        <Navbar />
        <AlertBanner />
        {hasExportRequests && <ExportPopUp />}
        <Layout.Content>{children}</Layout.Content>
      </Layout>
    </BaseLayout>
  )
}

export default LoggedInLayout
