import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import { apiConfig } from './config'

firebase.initializeApp(apiConfig.firebase)

const checkAuthStatus = (): Promise<firebase.User | null> => {
  return new Promise((resolve, reject) => {
    try {
      return firebase.auth().onAuthStateChanged((user) => resolve(user))
    } catch (err) {
      reject(err)
      return null
    }
  })
}

export const getToken = async () => {
  const currentUser = await checkAuthStatus()
  if (!currentUser) {
    return ''
  }

  const token = await currentUser?.getIdToken()
  return token
}
