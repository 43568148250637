import { NextRouter } from 'next/router'

type RouterQuery = {
  [key: string]: string | string[] | undefined
}

export const getOtherQueries = (routerQuery: NextRouter['query'], urlKey: string): RouterQuery => {
  const { [urlKey]: _, ...otherQueries } = routerQuery
  return otherQueries
}

export const updateUrlQuery = (newRouterQuery: RouterQuery, router: NextRouter, urlKey: string) => {
  const pathname = router.pathname
  const isTheSameQuery = newRouterQuery[urlKey] === router.query[urlKey]

  if (isTheSameQuery) {
    return
  }

  // NOTE: uses replace to avoid browser "back" button being stuck on the same page
  router.replace(
    {
      pathname,
      query: newRouterQuery,
      hash: router.asPath.split('#')[1],
    },
    undefined,
    { shallow: true },
  )
}
