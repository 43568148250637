import { Dropdown } from 'antd'
import { useState } from 'react'
import { ExportList, useListExports, usePollAsyncTask } from '@netpurpose/api'
import { useAlertBanner } from '@netpurpose/core'
import { CalloutMenu, ExportCard, Icon } from '@netpurpose/np-ui'
import { AnalyticsContextType, useAnalytics } from '#context/analytics'
import {
  AnalyticsButtonName,
  AnalyticsDownloadFileName,
  AnalyticsEventName,
} from '#types/analytics'
import { DropdownIconContainer, EmptyState, ExportHistoryLink } from './ExportHistoryDropdown.style'

const getThreeMostRecentExportItems = ({
  exports,
  onAction,
  analytics,
}: {
  exports: ExportList | undefined
  onAction: (resultId: string) => void
  analytics: AnalyticsContextType
}) => {
  return !exports || exports?.length === 0
    ? [
        {
          label: (
            <EmptyState>Your portfolio and company export history will appear here.</EmptyState>
          ),
          key: 'empty-state',
        },
      ]
    : [...exports]
        .sort((a, b) => {
          if (a.completedAt && b.completedAt) {
            return new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime()
          }
          return 0
        })
        .map((val) => ({
          label: (
            <ExportCard
              {...val}
              withBorder={false}
              onDownload={() => {
                analytics?.logEvent(AnalyticsEventName.ButtonClick, {
                  button_name: AnalyticsButtonName.ExportHistoryDropdownDownload,
                })
                onAction(val.resultId)
              }}
            />
          ),
          key: val.resultId,
        }))
        .slice(0, 3)
}

export const ExportHistoryDropdown = () => {
  const analytics = useAnalytics()
  const exports = useListExports()
  const { showAlertBanner } = useAlertBanner()
  const [selectedExportItemResultId, setSelectedExportItemResultId] = useState('')

  usePollAsyncTask({
    resultId: selectedExportItemResultId,
    onSuccess: (result) => {
      if (typeof result?.data === 'string') {
        analytics?.logEvent(AnalyticsEventName.AutoFileDownload, {
          // As this dropdown shows all exports, and all we have to work with is a token
          // to generate a download link, we won't actually know the file being downloaded.
          file_name: AnalyticsDownloadFileName.Unknown,
        })
        // Download the portfolio immediately without requiring user action.
        // Note that this may be blocked by a browser's popup blocker.
        window.open(result.data)
      }
    },
    onError: () => {
      showAlertBanner('customError', 'Unable to download portfolio. Token may be invalid.')
    },
  })

  const onAction = (resultId: string) => {
    // Result IDs returned from the API are UUIDs with dashes, but the /results endpoint
    // requires them without the dash.
    setSelectedExportItemResultId(resultId.replaceAll('-', ''))
  }

  const threeMostRecentExportItems = getThreeMostRecentExportItems({
    exports: exports.data,
    onAction,
    analytics,
  })
  const dropdownMenuItems = [
    ...threeMostRecentExportItems,
    {
      label: (
        <ExportHistoryLink href="/export-history">
          <Icon icon="PendingReverse" alt="" color="typePrimary" ariaHidden />
          <div>Export history</div>
        </ExportHistoryLink>
      ),
      key: 'export-history-link',
    },
  ]

  return (
    <Dropdown
      dropdownRender={() => <CalloutMenu removePadding items={dropdownMenuItems} />}
      trigger={['click']}
      placement="bottom"
    >
      <DropdownIconContainer>
        <Icon icon="Download" alt="Export history" color="typePrimary" />
      </DropdownIconContainer>
    </Dropdown>
  )
}
