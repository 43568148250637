import styled from 'styled-components'

export const TokenContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border: 2px solid ${({ theme }) => theme.colors.architecture6};
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  padding: 0.5rem;
  display: flex;
`

export const Monospaced = styled.span`
  font-family: 'DM Mono', monospace;
  overflow-x: hidden;
  margin-right: 1rem;
`

export const TextContainer = styled.div`
  margin-bottom: 1rem;
`

export const StatusContainer = styled.div`
  height: 2.5rem;
  padding: 0 1.25rem;
  box-sizing: border-box;
  border-width: ${({ theme }) => theme.border.width};
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.darkBlue};
  display: flex;
  align-items: center;
  padding: 0 1.25rem 0 0.5rem;

  > :first-child {
    margin-right: 0.5rem;
  }
`
