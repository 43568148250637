import { Optional } from '@netpurpose/types'

export type PaginationParams = {
  page: number
  pageSize: number
}
export type BackendPaginationParams = {
  page: number
  limit: number
}
type CombinedParams = Optional<PaginationParams & BackendPaginationParams, 'pageSize'>

export const formatPaginationParams = (params: PaginationParams): BackendPaginationParams => {
  const backendParams: CombinedParams = {
    limit: params.pageSize,
    ...params,
  }
  delete backendParams.pageSize
  return backendParams
}
