import { FC } from 'react'
import { ThemeColor } from '../../themes'
import { ButtonLevel, Props as ButtonProps } from '../Button/Button'
import { Icon } from '../Icon'
import { IconName } from '../Icon/icons'
import { StyledButton } from './ButtonWithIcon.style'

export type Props = ButtonProps & {
  icon: IconName
  iconAlt: string
  inheritColor?: boolean
}

const getIconColorName = (level: ButtonLevel): ThemeColor => {
  if (level === 'primary') {
    return 'typeDarkPrimary'
  }
  if (level === 'secondary') {
    return 'typePrimary'
  }
  if (level === 'tertiary') {
    return 'typePrimary'
  }
  if (level === 'danger') {
    return 'semanticNegative'
  }
  if (level === 'positive') {
    return 'semanticPositive'
  }
  return 'typePrimary'
}

export const ButtonWithIcon: FC<Props> = ({
  icon: iconName,
  iconAlt,
  level = 'secondary',
  inheritColor = false,
  ...restProps
}) => {
  const iconColorName = getIconColorName(level)
  return (
    <StyledButton
      icon={
        <Icon
          icon={iconName}
          alt={iconAlt}
          color={iconColorName}
          inheritColor={inheritColor}
          width={20}
          height={20}
        />
      }
      level={level}
      {...restProps}
    />
  )
}
