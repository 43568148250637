import { ButtonProps } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { FC } from 'react'
import { ThemeColor } from '../../themes'
import {
  ButtonWrapper,
  DangerButton,
  GhostButton,
  IconButton,
  LinkButton,
  NewPrimaryButton,
  NewSecondaryButton,
  PositiveButton,
  PrimaryButton,
  PrimarySubmitButton,
  SecondaryButton,
  TertiaryButton,
  TertiarySubmitButton,
  WarningButton,
} from './Button.style'

export type ButtonLevel =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiarySubmit'
  | 'submit'
  | 'danger'
  | 'positive'
  | 'link'
  | 'icon'
  | 'newPrimary'
  | 'newSecondary'
  | 'ghost'
  | 'warning'

export type Props = ButtonProps & {
  level?: ButtonLevel
  color?: ThemeColor
  fontWeight?: string
  children?: React.ReactNode
  fullWidth?: boolean
  size?: SizeType
  transparent?: boolean
}

const levelComponentMap = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton,
  tertiarySubmit: TertiarySubmitButton,
  danger: DangerButton,
  positive: PositiveButton,
  submit: PrimarySubmitButton,
  link: LinkButton,
  icon: IconButton,
  newPrimary: NewPrimaryButton,
  newSecondary: NewSecondaryButton,
  ghost: GhostButton,
  warning: WarningButton,
}

// we need the wrapper because of this bug: https://github.com/react-component/tooltip/issues/18
const Button: FC<Props> = ({
  children,
  level = 'secondary',
  fullWidth = false,
  size,
  transparent = false,
  ...restProps
}) => {
  const ButtonComponent = levelComponentMap[level]

  // NOTE: defaulting disabled to false and removing it from restProps in the function arguments
  // breaks formik disable on failed submit for some reason, so have opted for !! here
  return (
    <ButtonWrapper disabled={!!restProps.disabled} $fullWidth={fullWidth}>
      <ButtonComponent
        $fullWidth={fullWidth}
        $size={size}
        $transparent={transparent}
        {...restProps}
      >
        {children}
      </ButtonComponent>
    </ButtonWrapper>
  )
}

export default Button
