import styled from 'styled-components'

export const StyledButton = styled.button<{ $alignCenter?: boolean; $hasHoverState?: boolean }>`
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: ${({ $hasHoverState }) => ($hasHoverState ? '0.25rem' : '0')};

  ${({ $alignCenter }) =>
    $alignCenter
      ? `
      display: flex;
      align-self: center;
      `
      : ''}

  ${({ $hasHoverState, theme }) =>
    $hasHoverState
      ? `&:hover {
          background-color: ${theme.colors.architecture3};
          border-radius: ${theme.border.radius};
        }`
      : ''}
`
