import { PermissionsResourceType, User } from '@netpurpose/types'

export const checkInternalUser = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return !!user?.groups.find((group) => group.name === 'Net Purpose')
}

export const canListEstimations = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return user.hasPerm(PermissionsResourceType.EstimationsData, 'LIST')
}

export const canAccessPSIData = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return (
    user.hasPerm(PermissionsResourceType.EstimationsData, 'LIST') &&
    user.hasPerm(PermissionsResourceType.EstimationsData, 'GET')
  )
}

export const canAccessSDGRevenueAlignmentData = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return (
    user.hasPerm(PermissionsResourceType.SDGRevenueAlignment, 'INCLUDE_NP_DATA') ||
    user.hasPerm(PermissionsResourceType.SDGRevenueAlignment, 'INCLUDE_FACTSET_DATA')
  )
}

export const canListTechnologies = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return user.hasPerm(PermissionsResourceType.Technologies, 'LIST')
}

export const canGetTechnologies = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return user.hasPerm(PermissionsResourceType.Technologies, 'GET')
}

export const canCreateMetricsConfigs = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return user.hasPerm(PermissionsResourceType.MetricsConfig, 'CREATE', '?')
}

export const canEditMetricsConfigs = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return user.hasPerm(PermissionsResourceType.MetricsConfig, 'EDIT', '?')
}

export const canDeleteMetricsConfigs = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return user.hasPerm(PermissionsResourceType.MetricsConfig, 'DELETE', '?')
}

export const canAccessWatchlists = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return user.hasPerm(PermissionsResourceType.Portfolio, 'ACCESS_WATCHLISTS')
}

export const canAccessExplorePage = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return user.hasPerm(PermissionsResourceType.AppFeatures, 'ACCESS_EXPLORE')
}

export const canGetAPIKey = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return user.hasPerm(PermissionsResourceType.AppFeatures, 'GET_API_KEY')
}
