import { useRouter } from 'next/router'
import qs from 'qs'
import { apiConfig } from '../config'
import { LegacyParsedQuery } from './types'

export const useTableConfigFromUrl = <Model>({
  urlKey,
  enabled = true,
}: {
  urlKey?: string
  enabled?: boolean
}) => {
  const router = useRouter()
  if (!enabled || !urlKey) {
    return {
      filters: {} as LegacyParsedQuery<Model>['fc'],
      sorting: {} as LegacyParsedQuery<Model>['sc'],
      sortOrder: [],
      pagination: {
        tablePage: Number.NaN,
        apiPage: Number.NaN,
        pageSize: Number.NaN,
      },
    }
  }
  const { fc, sc, so, pc } = qs.parse(
    router?.query[urlKey] as string,
  ) as unknown as LegacyParsedQuery<Model>

  const filters = fc || {}
  const sorting = sc || {}
  const sortOrder = so || []
  const pagination = pc || {}

  const tablePage = Number(pagination?.currentPage || 1)
  return {
    filters,
    sorting,
    sortOrder,
    pagination: {
      tablePage,
      apiPage: tablePage - 1, // API is 0 indexed but table is 1 indexed
      pageSize: Number(pagination?.pageSize) || apiConfig.defaultFetchSize,
    },
  }
}
