import { FC } from 'react'
import { ThemeColor } from '../../themes'
import { Icon } from '../Icon'
import {
  ContentContainer,
  ContentText,
  FooterContainer,
  HeaderText,
  LightweightText,
  SectionContainer,
  SpacedContent,
  StyledModal,
} from './Modal.style'

type SubComponents = {
  ContentContainer: typeof ContentContainer
  SectionContainer: typeof SectionContainer
  FooterContainer: typeof FooterContainer
  HeaderText: typeof HeaderText
  LightweightText: typeof LightweightText
  ContentText: typeof ContentText
  SpacedContent: typeof SpacedContent
}

const sizingMap = {
  small: 460,
  medium: 680,
  large: 880,
  xlarge: 1200,
  max: 'auto',
}

export type ModalSize = 'small' | 'medium' | 'large' | 'xlarge' | 'max'

export type ModalProps = {
  title: string
  open: boolean
  size?: ModalSize
  onClose?: () => void
  zindex?: number
  headerBgColor?: ThemeColor
  withTabs?: boolean
  children?: React.ReactNode
  hideHeader?: boolean
  testId?: string
  hideScrollBar?: boolean
}

export const Modal: FC<ModalProps> & SubComponents = ({
  title,
  open,
  size = 'medium',
  onClose = () => {},
  children,
  zindex = 10,
  headerBgColor,
  withTabs = false,
  hideHeader = false,
  testId,
  hideScrollBar = false,
}) => {
  return (
    <StyledModal
      destroyOnClose
      centered
      title={title.toUpperCase()}
      open={open}
      maskClosable={false}
      closeIcon={<Icon icon="XLarge" alt="Close modal" color="bgSecondary" />}
      footer={null}
      onCancel={onClose}
      width={sizingMap[size]}
      isMaxHeight={size === 'max'}
      zIndex={zindex}
      headerBgColor={headerBgColor}
      withTabs={withTabs}
      hideHeader={hideHeader}
      data-testid={testId}
      hideScrollBar={hideScrollBar}
    >
      {children}
    </StyledModal>
  )
}

ContentContainer.displayName = 'Modal.ContentContainer'
Modal.ContentContainer = ContentContainer

SectionContainer.displayName = 'Modal.SectionContainer'
Modal.SectionContainer = SectionContainer

FooterContainer.displayName = 'Modal.FooterContainer'
Modal.FooterContainer = FooterContainer

HeaderText.displayName = 'Modal.HeaderText'
Modal.HeaderText = HeaderText

LightweightText.displayName = 'Modal.LightweightText'
Modal.LightweightText = LightweightText

LightweightText.displayName = 'Modal.ContentText'
Modal.ContentText = ContentText

SpacedContent.displayName = 'Modal.SpacedContent'
Modal.SpacedContent = SpacedContent
