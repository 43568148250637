import { Filters, Sorting } from '@netpurpose/types'
import { FilterState, State } from './reducer'

export const selectFilters = <Model>(state: State<Model>): Filters<Model> =>
  Object.entries<FilterState<Model>[keyof Model]>(state.filters).reduce((acc, [k, v]) => {
    if (!v || !v.filters) {
      return acc
    }
    return { ...acc, [k]: v.filters }
  }, {} as Filters<Model>)

export const selectSorting = <Model>(state: State<Model>): Sorting<Model> =>
  Object.entries<FilterState<Model>[keyof Model]>(state.filters).reduce((acc, [k, v]) => {
    if (!v || !v.sortDirection) {
      return acc
    }
    return { ...acc, [k]: v.sortDirection }
  }, {} as Sorting<Model>)
