import { getToken } from './auth'
import { apiConfig } from './config'
import { AppClient as FactApi } from './generated/facts'
import { AppClient as ModellingApi } from './generated/modelling'
import { AppClient as SemanticApi } from './generated/semantic'
import { AppClient as UsersApi } from './generated/users'

export const GeneratedFactApi = new FactApi({
  BASE: apiConfig.factsHost,
  TOKEN: getToken,
})

export const GeneratedModellingApi = new ModellingApi({
  BASE: apiConfig.modellingHost,
  TOKEN: getToken,
})

export const GeneratedUsersApi = new UsersApi({
  BASE: apiConfig.usersHost,
  TOKEN: getToken,
})

export const GeneratedSemanticApi = new SemanticApi({
  BASE: apiConfig.semanticHost,
  TOKEN: getToken,
})
