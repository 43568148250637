import { useMutation, useQueryClient } from '@tanstack/react-query'
import { MetricsConfig } from '@netpurpose/types'
import { GeneratedUsersApi } from '../../GeneratedApi'
import { MetricsConfigApi } from '../../models'
import {
  getUseDeleteModel,
  getUseModel,
  getUseModelListFromPaginatedApi,
  getUsePaginatedModel,
} from '../useModel'

export const usePaginatedMetricConfigs = getUsePaginatedModel<MetricsConfig, MetricsConfigApi>(
  'metricConfigsPaginated',
  'metricsConfig',
)

export const useMetricConfigs = getUseModelListFromPaginatedApi<MetricsConfig, MetricsConfigApi>(
  'metricConfigsList',
  'metricsConfig',
)

export const useMetricConfig = getUseModel<MetricsConfig, MetricsConfigApi>(
  'metricConfig',
  'metricsConfig',
)

export const useCreateMetricConfig = () =>
  useMutation({
    mutationFn: ({ name, questionIds }: { name: string; questionIds: number[] }) =>
      GeneratedUsersApi.metricConfig.createMetricsConfig({
        requestBody: {
          name,
          question_ids: questionIds,
        },
      }),
  })

export const useUpdateMetricConfig = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      metricConfigId,
      name,
      questionIds,
    }: {
      metricConfigId: string
      name: string
      questionIds: number[]
    }) =>
      GeneratedUsersApi.metricConfig.putUpdateMetricsConfig({
        metricsConfigId: metricConfigId,
        requestBody: {
          name,
          question_ids: questionIds,
        },
      }),
    onSuccess: (_, { metricConfigId }) => {
      queryClient.invalidateQueries({
        queryKey: ['metricConfig'],
      })
      // We need to invalidate these in case the name has changed.
      queryClient.invalidateQueries({
        queryKey: ['metricConfigsPaginated'],
      })
      queryClient.invalidateQueries({
        queryKey: ['metricConfigsList'],
      })
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(metricConfigId),
      })
    },
  })
}

export const useDeleteMetricConfig = getUseDeleteModel<MetricsConfig, MetricsConfigApi>(
  'metricsConfig',
)
