import { useQuery } from '@tanstack/react-query'
import { GeneratedFactApi } from '../../GeneratedApi'

export const usePaginatedGlobalSearch = ({ searchTerm }: { searchTerm: string }) => {
  const { data, ...rest } = useQuery({
    queryKey: ['search', searchTerm],

    queryFn: () => GeneratedFactApi.search.globalSearch({ q: `search_term=${searchTerm}&limit=8` }),
    enabled: !!searchTerm,
  })
  return { data: data?.results, ...rest }
}
