import styled from 'styled-components'
import { ThemeColor } from '../../themes'

type Props = {
  color?: ThemeColor | undefined
}

export const H1 = styled.h1<Props>`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.typePrimary)};
`

export const H2 = styled.h2<Props>`
  font-weight: 700;
  font-size: 1.5rem;
  font-family: Blacker, Gilroy, serif;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.typePrimary)};
  line-height: 2rem;
`

export const H3 = styled.h3<Props>`
  font-weight: 700;
  font-size: 1.125rem;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.typePrimary)};
`

export const H4 = styled.h4<Props>`
  font-weight: 700;
  font-size: 0.875rem;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.typeTertiary)};
`

export const H5 = styled.h5<Props>`
  font-weight: 700;
  font-size: 0.75rem;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.typeTertiary)};
`

export const H6 = styled.h6<Props>`
  font-weight: 700;
  font-size: 0.625rem;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.typeTertiary)};
  text-transform: uppercase;
`
