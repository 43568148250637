import { add, format, parse, sub, subMonths } from 'date-fns'

export const formatDate = (date: Date) => format(date, 'dd-MM-yyyy')
export const formatDateForTable = (date: Date) => format(date, 'yyyy-MM-dd')
export const formatDateForTableWithTime = (date: Date) => format(date, 'yyyy-MM-dd HH:mm')
export const formatDateWithTime = (date: Date) => format(date, 'dd-MM-yyyy HH:mm')
export const formatDateWithTimeAlternate = (date: Date) => format(date, 'dd/MM/yyyy HH:mm')
export const formatDateAlternate = (date: Date) => format(date, 'dd/MM/yyyy')

export const getMonthsAgoDateString = (numMonths: number) => {
  const monthsAgo = subMonths(new Date(), numMonths)
  return format(monthsAgo, 'yyyy-MM-dd')
}

export const getPreviousDay = (date: string) => {
  const day = parse(date, 'yyyy-MM-dd', new Date())
  const previousDay = sub(day, { days: 1 })
  return format(previousDay, 'yyyy-MM-dd')
}

export const getNextDay = (date: string) => {
  const day = parse(date, 'yyyy-MM-dd', new Date())
  const nextDay = add(day, { days: 1 })
  return format(nextDay, 'yyyy-MM-dd')
}

export const getDateOneYearAgo = (date: string) => {
  const parsedDate = parse(date, 'yyyy-MM-dd', new Date())
  const dateOneYearAgo = sub(parsedDate, { years: 1 })
  return format(dateOneYearAgo, 'yyyy-MM-dd')
}
