import { FC } from 'react'
import { useModalContext } from '@netpurpose/core'
import { CalloutMenu, Icon } from '@netpurpose/np-ui'
import { useAnalytics } from '#context/analytics'
import { AnalyticsButtonName, AnalyticsEventName } from '#types/analytics'

export const ApiKeyOption: FC = () => {
  const { openModal } = useModalContext()
  const analytics = useAnalytics()

  return (
    <CalloutMenu.Item
      key="api-key"
      label="Get API key"
      onClick={() => {
        analytics?.logEvent(AnalyticsEventName.ButtonClick, {
          button_name: AnalyticsButtonName.NavBarGetAPIKey,
        })
        openModal('apiKey')
      }}
      renderIcon={() => <Icon icon="Key" alt="Key" />}
    />
  )
}
