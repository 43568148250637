import { Command } from 'cmdk'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDebounceValue } from 'usehooks-ts'
import { usePaginatedGlobalSearch } from '@netpurpose/api'
import { SearchResult } from '@netpurpose/api/src/generated/facts'
import { useModalContext } from '@netpurpose/core'
import { Icon, Spinner } from '@netpurpose/np-ui'
import {
  IconTextContainer,
  KeyboardPrompt,
  NavBarContainer,
  StyledCmdkRoot,
} from './GlobalSearch.style'

const SearchGroup = ({
  results,
  heading,
  baseUrl,
  setOpen,
  setSearch,
}: {
  results: SearchResult[] | undefined
  heading: string
  baseUrl: string
  setOpen: Dispatch<SetStateAction<boolean>>
  setSearch: Dispatch<SetStateAction<string>>
}) => {
  const router = useRouter()

  if (!results) {
    return null
  }

  return (
    <Command.Group heading={heading}>
      {results?.map(({ key, title, text }) => (
        <Command.Item
          onSelect={() => {
            setOpen(false)
            setSearch('')
            router.push(`${baseUrl}/${key}`)
          }}
          key={key}
          value={text}
        >
          <Icon icon="ArrowRight" alt="" height={24} width={24} />
          {title}
        </Command.Item>
      ))}
    </Command.Group>
  )
}

const SearchDialog = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const [search, setSearch] = useState('')

  const [debounceSearch] = useDebounceValue(search, 300)

  const { data: searchResults, isLoading: isLoadingSearchResults } = usePaginatedGlobalSearch({
    searchTerm: debounceSearch,
  })

  const entityResults = searchResults?.filter((result) => result.type === 'entity')
  const portfolioResults = searchResults?.filter((result) => result.type === 'portfolio')

  const { isAnyModalOpen } = useModalContext()

  const down = useCallback(
    (event: KeyboardEvent) => {
      if (isAnyModalOpen) {
        return
      }

      if (event.key === 'Escape') {
        event.preventDefault()
        setSearch('')
        setOpen(false)
      }
      if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault()
        setSearch('')
        setOpen((openState) => !openState)
      }
    },
    [isAnyModalOpen, setOpen],
  )

  useEffect(() => {
    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [down])

  if (!open) {
    return null
  }

  const hasResults = !!(entityResults?.length || portfolioResults?.length)

  return (
    <StyledCmdkRoot $hasResults={hasResults || isLoadingSearchResults}>
      <Command
        loop
        onClick={() => {
          setOpen(false)
          setSearch('')
        }}
      >
        <Command.Input
          value={search}
          onValueChange={setSearch}
          placeholder="Find a company or portfolio in the Net Purpose universe..."
          autoFocus
          // This stops the Command onClick closing the search dialog when clicking on the input or list
          onClick={(event) => event.stopPropagation()}
        />
        <Command.List>
          {isLoadingSearchResults && (
            <Command.Loading>
              <Spinner size="small" />
            </Command.Loading>
          )}
          <SearchGroup
            results={entityResults}
            heading="Companies"
            baseUrl="/explore"
            setOpen={setOpen}
            setSearch={setSearch}
          />
          <SearchGroup
            results={portfolioResults}
            heading="Portfolios"
            baseUrl="/portfolios"
            setOpen={setOpen}
            setSearch={setSearch}
          />
        </Command.List>
      </Command>
    </StyledCmdkRoot>
  )
}

export const GlobalSearch = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <SearchDialog open={open} setOpen={setOpen} />
      <NavBarContainer onClick={() => setOpen(true)}>
        <IconTextContainer>
          <Icon icon="Search" alt="" inheritColor height={20} width={20} />
          Search
        </IconTextContainer>
        <KeyboardPrompt>{navigator.userAgent.includes('Mac') ? '⌘K' : 'Ctrl+K'}</KeyboardPrompt>
      </NavBarContainer>
    </>
  )
}
