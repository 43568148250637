import { SortDirection, Sorting, SortOrder } from '@netpurpose/types'
import { getQueryMapper, ReverseFieldMap } from './fieldMapping'

export const formatSortParams = <Model>(
  sorting: Sorting<Model>,
  sortOrder: SortOrder<Model>,
  fieldMapper: Partial<ReverseFieldMap<keyof Model>>,
) => {
  const queryMapper = getQueryMapper(fieldMapper)
  return {
    sort_by: sortOrder.map((column) => {
      const isAscending = sorting[column] === SortDirection.ASC
      const [backendColumn] = queryMapper(column, [])
      return isAscending ? backendColumn : `-${backendColumn}`
    }),
  }
}
