import { useMetricConfigs } from '@netpurpose/api'
import { Icon, IconButton } from '@netpurpose/np-ui'
import {
  ALL_MAJOR_METRICS_ID,
  ALL_MAJOR_METRICS_LABEL,
  MetricsConfig,
  ResultStatus,
} from '@netpurpose/types'
import { useAnalytics } from '#context/analytics'
import { TaskType, useTasks } from '#context/tasks'
import { AnalyticsButtonName, AnalyticsEventName } from '#types/analytics'
import {
  ExportFailureText,
  ExportItemContainer,
  ExportName,
  ExportNameAndIcon,
  ExportSuccessText,
  LoadingIndicator,
  PopUpContainer,
  PopUpHeader,
  StatusIconContainer,
} from './ExportPopUp.style'

const ExportItem = ({
  exportName,
  status,
  result,
  removeExport,
}: {
  exportName: string
  status: ResultStatus
  result: string | undefined
  removeExport: (status: 'success' | 'failed') => void
}) => {
  if (status === 'pending') {
    return (
      <ExportItemContainer>
        <ExportNameAndIcon>
          <StatusIconContainer>
            <Icon icon="Downloading" alt="Downloading" height={24} width={24} color="typePrimary" />
          </StatusIconContainer>
          <div>
            <ExportName>{exportName}</ExportName>
          </div>
        </ExportNameAndIcon>
        <LoadingIndicator loading={true} size="large" />
      </ExportItemContainer>
    )
  }

  if (status === 'complete') {
    return (
      <ExportItemContainer>
        <ExportNameAndIcon>
          <StatusIconContainer>
            <Icon
              icon="TickInCircle"
              alt="Complete"
              height={24}
              width={24}
              color="semanticPositive"
            />
          </StatusIconContainer>
          <div>
            <ExportName>{exportName}</ExportName>
            <ExportSuccessText>Export ready to download</ExportSuccessText>
          </div>
        </ExportNameAndIcon>

        <IconButton
          icon="Download"
          iconAlt="Download"
          color="typePrimary"
          height={24}
          width={24}
          onClick={() => {
            window.open(result)
            removeExport('success')
          }}
          alignCenter
          style={{ cursor: 'pointer' }}
        />
      </ExportItemContainer>
    )
  }

  return (
    <ExportItemContainer>
      <ExportNameAndIcon>
        <StatusIconContainer>
          <Icon
            icon="ExclamationInCircle"
            alt="Failed"
            height={24}
            width={24}
            color="semanticNegative"
          />
        </StatusIconContainer>
        <div>
          <ExportName>{exportName}</ExportName>
          <ExportFailureText>Export failed.</ExportFailureText>
        </div>
      </ExportNameAndIcon>
      <IconButton
        icon="Close"
        iconAlt="Remove"
        color="typePrimary"
        height={24}
        width={24}
        alignCenter
        onClick={() => removeExport('failed')}
      />
    </ExportItemContainer>
  )
}

const getMetricConfigName = (
  metricConfigs: MetricsConfig[] | undefined,
  metricConfigId: string | undefined,
) =>
  metricConfigId === ALL_MAJOR_METRICS_ID
    ? ALL_MAJOR_METRICS_LABEL
    : metricConfigs?.find((mc) => mc.id === metricConfigId)?.name

// Currently only used within LoggedInLayout. If used elsewhere, needs to include
// a check for any export requests before rendering the component, otherwise
// could lead to tests unexpectedly failing. Done this way to avoid having to
// remember to mock useMetricConfigs (or any other hooks that get added later).
export const ExportPopUp = () => {
  const {
    portfolioExportRequests,
    portfolioPAIsExportRequests,
    portfolioSDGsExportRequests,
    portfolioSDGRevenueExportRequests,
    dataDictionaryExportRequests,
    entityExportRequests,
    removeExport,
  } = useTasks()

  const { data: metricConfigs } = useMetricConfigs()

  const analytics = useAnalytics()

  return (
    <PopUpContainer>
      <PopUpHeader>Preparing export</PopUpHeader>
      {portfolioExportRequests.map((req) => (
        <ExportItem
          exportName={`${req.portfolioName} - ${getMetricConfigName(metricConfigs, req.metricConfigId)}`}
          status={req.status}
          result={req.result}
          removeExport={(status) => {
            removeExport(TaskType.PortfolioExport, req.result)
            if (status === 'success') {
              analytics?.logEvent(AnalyticsEventName.ButtonClick, {
                button_name: AnalyticsButtonName.DownloadPortfolio,
                portfolio_id: req.initial,
                metric_config_id: req.metricConfigId || null,
              })
            }
          }}
          key={`portfolio ${req.metricConfigId}`}
        />
      ))}
      {portfolioSDGsExportRequests.map((req) => (
        <ExportItem
          exportName={`${req.portfolioName} - SDG Outcomes`}
          status={req.status}
          result={req.result}
          removeExport={(status) => {
            removeExport(TaskType.PortfolioSDGsExport, req.result)
            if (status === 'success') {
              analytics?.logEvent(AnalyticsEventName.ButtonClick, {
                button_name: AnalyticsButtonName.DownloadPortfolioSDGs,
                portfolio_id: req.initial,
              })
            }
          }}
          key={`portfolio sdg ${req.portfolioName}`}
        />
      ))}
      {portfolioSDGRevenueExportRequests.map((req) => (
        <ExportItem
          exportName={`${req.portfolioName} - SDG Revenue`}
          status={req.status}
          result={req.result}
          removeExport={(status) => {
            removeExport(TaskType.PortfolioSDGRevenueExport, req.result)
            if (status === 'success') {
              analytics?.logEvent(AnalyticsEventName.ButtonClick, {
                button_name: AnalyticsButtonName.DownloadPortfolioSDGRevenue,
                portfolio_id: req.initial,
              })
            }
          }}
          key={`portfolio sdg revenue ${req.portfolioName}`}
        />
      ))}
      {portfolioPAIsExportRequests.map((req) => (
        <ExportItem
          exportName={`${req.portfolioName} - PAIs`}
          status={req.status}
          result={req.result}
          removeExport={(status) => {
            removeExport(TaskType.PortfolioPAIsExport, req.result)
            if (status === 'success') {
              analytics?.logEvent(AnalyticsEventName.ButtonClick, {
                button_name: AnalyticsButtonName.DownloadPortfolioPAIs,
                portfolio_id: req.initial,
              })
            }
          }}
          key={`portfolio PAIs ${req.portfolioName}`}
        />
      ))}
      {dataDictionaryExportRequests.map((req) => (
        <ExportItem
          exportName="Data Dictionary"
          status={req.status}
          result={req.result}
          removeExport={(status) => {
            removeExport(TaskType.DataDictionaryExport, req.result)
            if (status === 'success') {
              analytics?.logEvent(AnalyticsEventName.ButtonClick, {
                button_name: AnalyticsButtonName.DownloadDataDictionary,
              })
            }
          }}
          key="data dictionary"
        />
      ))}
      {entityExportRequests.map((req) => (
        <ExportItem
          exportName={req.entityName ?? 'Company Export'}
          status={req.status}
          result={req.result}
          removeExport={(status) => {
            removeExport(TaskType.EntityExport, req.result)
            if (status === 'success') {
              analytics?.logEvent(AnalyticsEventName.ButtonClick, {
                button_name: AnalyticsButtonName.DownloadEntityReport,
                entity_id: req.initial,
              })
            }
          }}
          key={`entity ${req.entityName}`}
        />
      ))}
    </PopUpContainer>
  )
}
